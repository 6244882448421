export const environment = {
    production: false,

    /**
     * Unique Platform Identifier
     */
    PLATFORM: 'REWARDS',

    /**
     * Backend APIs
     */
    IXFI_WS_API: 'https://api.zen-ex.com/',
    IXFI_API: 'https://api.zen-ex.com/api/front/',

    /**
     * Application Related URLs
     */
    CDN_URL: 'https://dfwt0d2sx0pdr.cloudfront.net/',
    LANGUAGE_URL: 'assets/i18n/',
    DOMAIN: 'zen-ex.com',
    RELEASE_VERSION: '73f6a13e-d951-42bf-97e4-bcfb627c23a5',

    /**
     * Web URLs
     */
    IXFI_EXCHANGE: 'https://staging.zen-ex.com/',
    IXFI_REWARDS: 'https://points.zen-ex.com/',
    IXFI_RESEARCH: 'https://research-staging.zen-ex.com/',
    IXFI_STAKING: 'https://staking-earn.zen-ex.com/',
    IXFI_STATUS: 'https://status.zen-ex.com/',
    IOS_APP_LINK: 'https://apps.apple.com/ro/app/ixfi/id1614823165',
    ANDROID_APP_LINK: 'https://play.google.com/store/apps/details?id=com.ixfi',
    NFT_WEB_URL: 'https://nft.zen-ex.com/',
    IXFI_CARDS: 'https://cards-staging.zen-ex.com/',
    IXFI_ABOUT: 'https://www.ixfi.com/go/about/',
    IXFI_BLOG: 'https://www.ixfi.com/blog/',
    IXFI_TOKEN: 'https://staging.zen-ex.com/token/',

    /**
     * Mobile URLs
     */
    ANDROID_APP: 'https://play.google.com/store/apps/details?id=com.ixfi',
    IOS_APP: 'https://apps.apple.com/us/app/ixfi/id1614823165',

    /**
     * 3rd Party Affiliates
     */

    INTERCOM_APP_ID: 'hll0wikw'
};
