import { Component, ElementRef, inject, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { APP_CONSTANTS } from 'src/app/shared/models/enum.constants';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-terms-and-conditions-modal',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './terms-and-conditions-modal.component.html',
    styleUrls: ['./terms-and-conditions-modal.component.scss']
})
export class TermsAndConditionsModalComponent implements OnInit, OnDestroy {
    @Input() description!: string;
    @Input() userBanNotice!: boolean;
    @Input() isExchange_TnC!: boolean;
    safeHTMLContent!: SafeHtml;
    APP_CONSTANTS = APP_CONSTANTS;
    private ele = inject(ElementRef);
    interval!: NodeJS.Timeout;
    constructor(
        private activeModal: NgbActiveModal,
        private sanitizer: DomSanitizer,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    ngOnInit(): void {
        this.safeHTMLContent = this.sanitizer.bypassSecurityTrustHtml(this.description);
        if (this.isExchange_TnC && this.description) {
            this.removeStyle();
        }
        if (isPlatformBrowser(this.platformId)) {
            this.interval = setTimeout(() => {
                const ele = document.getElementById('tnCBody');
                ele?.scrollTo(0, 0);
            }, 0);
        }
    }

    onClose(reason: string) {
        this.activeModal.dismiss(reason);
    }

    removeStyle(): void {
        if (isPlatformBrowser(this.platformId)) {
            const interval = setTimeout(() => {
                this.ele.nativeElement.querySelectorAll('[style]').forEach(el => {
                    const preserveStyle = el.style.whitespace;
                    if (!preserveStyle) {
                        el.removeAttribute('style');
                    }
                });
                clearInterval(interval);
            }, 0);
        }
    }

    ngOnDestroy(): void {
        if (this.interval) clearInterval(this.interval);
    }
}
