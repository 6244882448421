export enum ToastrEnum {
    SUCCESS_TIMEOUT = 3000,
    ERROR_TIMEOUT = 5000,
    WARNING_TIMEOUT = 5000
}

export enum STORAGE_KEY {
    TOKEN = 'token',
    IS_LOGGED_IN = 'isLoggedIn',
    USER = 'user',
    GEE_TEST = 'geetest',
    EMAIL_LOGIN = 'emailLogin',
    SEND_TO = 'sendTo',
    EMAIL_DATA = 'emaildata',
    EMAIL = 'email',
    PHONE_DATA = 'phonedata',
    CODE_COUNTY = 'code_country',
    MOBILE_COUNTRY_ID = 'mobile_country_id',
    GOOGLE_AUTH_DATA = 'google_auth_data',
    RETURN_URL = 'returnUrl',
    FORM_DATA_VERIFY_OTP = 'formDataVerifyOtp',
    TOKEN_EXPIRE_TIME = 'token_expire_time',
    SELECTED_CURRENCY = 'selectedCurrency',
    SELECTED_LANGUAGE = 'selectedLanguage',
    IS_MOBILE = 'is_mobile',
    EXPIRE_TIME = 'token_expire_time',
    REFRESH_TOKEN = 'refreshToken',
    IS_DARK_MODE = 'isDarkMode',
    IS_PROFILE_COMPLETE = 'is_profile_complete',
    AUTO_THEME = 'autoTheme',
    DEFAULT_CURRENCY_CODE = 'usd',
    SET_AMOUNT = 'setAmount',
    IS_SUBDOMAIN = 'isSubDomain',
    COOKIE_CONSENT = 'cookieConsent',
    DOWNLOAD_APP = 'appDownload'
}

export enum API_RESPONSE_STATUS {
    //success
    success_code = 200,
    two_factor_needed = 600,
    per_page_limit = 10,
    accepted_code = 202,
    sms_auth_needed = 601,
    sms_two_factor_auth_needed = 603,

    //error visible
    limit_reached = 429,
    bad_request_code = 400,
    server_error_code = 500,

    // server
    unauthorized_code = 401,
    forbidden_code = 403,

    // error disable
    email_not_verified = 417,
    authentication_expired = 419,
    service_unavailable = 503
}

export enum APP_CONSTANTS {
    PAGE_SIZE = 25,
    LEADER_BOARD_PAGE_SIZE = 30,
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    ACCEPTED_TNC = 'acceptedTnc',
    DECLINED_TNC = 'declinedTnc',
    REWARDS_ROOM = 'rewards',
    NEW_CLAIM_EVENT = 'new-claim',
    SMALL_DEVICE_RESOLUTION = 993,
    XXL_DEVICE_RESOLUTION = 1400,
    WINNER_PAGE_SIZE = 24
}
