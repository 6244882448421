import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IxfiThemeService } from 'header';
import { AuthService } from './auth.service';
import { APP_CONSTANTS } from '../../shared/models/enum.constants';
import { UserService } from './user.service';
import { isPlatformBrowser } from '@angular/common';
import { TermsAndConditionsModalComponent } from 'src/app/standalone/components/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService implements OnDestroy {
    modalRef!: NgbModalRef;
    subscriptions: Subscription[] = [];

    constructor(
        private userService: UserService,
        private ngbModal: NgbModal,
        private authService: AuthService,
        private theme: IxfiThemeService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        const auth$ = this.authService.loginStatus.subscribe(status => {
            if (!status) {
                this.modalRef?.dismiss(-1);
            }
        });
        this.subscriptions.push(auth$);
    }

    openTermsAndConditions() {
        if (this.authService.isLoggedIn() && isPlatformBrowser(this.platformId)) {
            this.userService.getUserBasicData().then((user: any) => {
                if (Object.keys(user).length && !user.term_conditions) {
                    this.showTncModal(true);
                }
                if (Object.keys(user).length && !user.is_tnc_loyalty_program) {
                    this.showTncModal();
                }
                if (Object.keys(user).length && user?.is_loyalty_banned_by_admin) {
                    this.showBanNotice();
                }
            });
        }
    }

    showTncModal(isExchange_TnC?) {
        const tnc$ = isExchange_TnC ? this.userService.getTncContentExchange() : this.userService.getTncContent();

        const tncSubscription = tnc$.subscribe(res => {
            this.modalRef = this.ngbModal.open(TermsAndConditionsModalComponent, {
                scrollable: true,
                size: 'xl',
                centered: true,
                backdrop: 'static',
                keyboard: false,
                modalDialogClass: 'terms-modal ixfi-modal ' + (this.theme.isDarkModeEnabled ? 'terms-dark-mode' : '')
            });

            this.modalRef.componentInstance.description = res?.description || '';
            this.modalRef.componentInstance.isExchange_TnC = isExchange_TnC;
            this.modalRef.dismissed.subscribe((reason: string) => {
                if (reason?.trim() === APP_CONSTANTS.ACCEPTED) {
                    this.userService.agreeToTnC().subscribe();
                } else if (reason?.trim() === APP_CONSTANTS.ACCEPTED_TNC) {
                    this.userService.agreeToTnCExchange().subscribe();
                } else if (reason?.trim() === APP_CONSTANTS.DECLINED) {
                    this.authService.redirectToPlatformUrl('');
                } else if (reason?.trim() === APP_CONSTANTS.DECLINED_TNC) {
                    this.authService.clearAuthData();
                }
            });
        });

        this.subscriptions.push(tncSubscription);
    }

    showBanNotice() {
        const ban$ = this.userService.getBanNotice().subscribe(res => {
            this.modalRef = this.ngbModal.open(TermsAndConditionsModalComponent, {
                scrollable: true,
                size: 'xl',
                centered: true,
                backdrop: 'static',
                keyboard: false,
                modalDialogClass: 'terms-modal ixfi-modal ' + (this.theme.isDarkModeEnabled ? 'terms-dark-mode' : '')
            });
            this.modalRef.componentInstance.userBanNotice = true;
            this.modalRef.componentInstance.description = res?.description || '';
            const modal$ = this.modalRef.dismissed.subscribe((reason: string) => {
                if (reason?.trim() === APP_CONSTANTS.ACCEPTED) {
                    this.authService.redirectToPlatformUrl('');
                }
            });
            this.subscriptions.push(modal$);
        });
        this.subscriptions.push(ban$);
    }

    checkUserBanStatus(): boolean {
        return !!this.userService.userBasicData?.is_loyalty_banned_by_admin;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
