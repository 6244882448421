import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from './api.service';
import { STORAGE_KEY } from '../../shared/models/enum.constants';
import { map, Observable, of, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../../shared/models/user.model';

export interface TncHTMLResponse {
    _id: string;
    description: string;
    updated_at: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserService implements OnDestroy {
    userBasicData!: User | any;
    subscription!: Subscription;

    constructor(
        private apiService: ApiService,
        private cookieService: CookieService
    ) {}

    getUpdatedProfile() {
        return this.apiService.postPlatform('v1/users/updated-profile');
    }

    changePromoEmailStatus(promoStatus: any) {
        return this.apiService.postPlatform('v1/users/update-promo-mails-settings', promoStatus);
    }

    setUserBasicData(userObject: User | any) {
        this.userBasicData = userObject ?? {};
    }

    getUserBasicData() {
        return new Promise(fulfill => {
            let isLoggedIn = JSON.parse(this.cookieService.get(STORAGE_KEY.IS_LOGGED_IN) || 'false');
            if (isLoggedIn) {
                if (this.userBasicData && Object.keys(this.userBasicData)?.length) {
                    fulfill(this.userBasicData);
                } else {
                    this.subscription = this.getUpdatedProfile()
                        .pipe(
                            map(res => {
                                if (res.status === 200) {
                                    this.setUserBasicData(res.data);
                                    return res.data;
                                } else {
                                    this.setUserBasicData({});
                                    return {};
                                }
                            })
                        )
                        .subscribe({
                            next(res) {
                                fulfill(res);
                            },
                            error(err) {
                                console.error(err);
                                fulfill(null);
                            }
                        });
                }
            } else {
                this.setUserBasicData({});
                fulfill(null);
            }
        });
    }

    // is_tnc_loyalty_program
    agreeToTnC() {
        return this.apiService.getPlatform('v1/reward-points/accept-tnc');
    }

    getTncContent(): Observable<TncHTMLResponse | undefined> {
        return this.apiService.getPlatform('reward-points/get-tnc').pipe(
            map((res: { status: number; data: TncHTMLResponse }) => {
                if (res.status == 200) {
                    return res.data;
                } else {
                    return undefined;
                }
            })
        );
    }

    // is_tnc_exchange
    getTncContentExchange(): Observable<any> {
        return this.apiService.getPlatform('lending-page/page-detail-by-language?slug=terms').pipe(
            map((res: { status: number; data: any }) => {
                if (res.status == 200) {
                    return res.data;
                } else {
                    return undefined;
                }
            })
        );
    }

    agreeToTnCExchange() {
        return this.apiService.putPlatform('v1/users/terms-and-condition');
    }

    getBanNotice() {
        return this.apiService.getPlatform('lending-page/get/user-ban-notice').pipe(
            map((res: { status: number; data: TncHTMLResponse }) => {
                if (res.status == 200) {
                    return res.data;
                } else {
                    return undefined;
                }
            })
        );
    }

    //=============get intercom user details=================================//

    getIntercomUserDetail(): Observable<{
        data: { intercomId: string; intercomHash: string };
        status: number;
        message: string;
    }> {
        return this.apiService.postPlatform('v1/users/get-intercom-id', { device_type: 'web' });
    }

    preCheck() {
        return this.apiService.getPlatform('v1/reward-points/pre-check-loyalty');
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }
}
